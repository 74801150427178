module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-async/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"HPGBenefits","short_name":"HPGBenefits","start_url":"/","background_color":"#fff","theme_color":"#333333","display":"standalone","icon":"static/logos/hpg-logo-mobile.png"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
